* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  user-select: none;
}
.icon-cancel {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 3em;
  padding: 3px;
  color: #f56565;
  background: #ffffff;
  border-radius: 50px;
  /* box-shadow: 5px 10px 13px #ebeb00, -5px -1px 13px #ffff00; */
  box-shadow: 5px 11px 22px #d9d9d9, -5px -1px 22px #ffffff;
}
.icon-cancel:active {
  box-shadow: inset 11px 11px 22px #d9d9d9, inset -11px -11px 22px #ffffff;
}
